<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-card-title>
      Modificação de senha
    </v-card-title>

    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- <v-text-field
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Senha anterior"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field> -->

              <v-text-field
                v-model="password"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Nova senha"
                outlined
                dense
                hint="Necessário ter pelo menos 8 caracteres"
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <v-text-field
                v-model="passwordConfirm"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirmar senha"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            @click="updatePassword"
          >
            Save changes
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
            @click="cancelAction"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiEyeOffOutline, mdiEyeOutline, mdiKeyOutline, mdiLockOpenOutline,
} from '@mdi/js'

export default {
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      password: '',
      passwordConfirm: '',
      isCPasswordVisible: false,
      isNewPasswordVisible: false,
      isCurrentPasswordVisible: false,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },

  methods: {
    async updatePassword() {
      const confirm = this.password === this.passwordConfirm ? this.password : 'As senhas são diferentes!!'

      if (confirm === 'As senhas são diferentes!!') {
        this.$swal({
          icon: 'error',
          title: 'Erro',
          showConfirmButton: false,
          text: confirm,
          timer: 3000,
        })

        return
      }

      const body = {
        name: this.accountData.name,
        cpf: this.accountData.cpf,
        email: this.accountData.email,
        password: confirm,
      }

      await axiosIns.put(`/api/v1/records/user/update/${this.accountData.id}`, body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso',
            showConfirmButton: false,
            text: 'Senha alterada!',
            timer: 3000,
          })
        })

      this.cancelAction()
    },

    cancelAction() {
      this.password = ''
      this.passwordConfirm = ''
    },
  },
}
</script>
