import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-5",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Modificação de senha ")]),_c(VForm,[_c('div',{staticClass:"px-3"},[_c(VCardText,{staticClass:"pt-5"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6"}},[_c(VTextField,{attrs:{"type":_vm.isNewPasswordVisible ? 'text' : 'password',"append-icon":_vm.isNewPasswordVisible ? _vm.icons.mdiEyeOffOutline:_vm.icons.mdiEyeOutline,"label":"Nova senha","outlined":"","dense":"","hint":"Necessário ter pelo menos 8 caracteres","persistent-hint":""},on:{"click:append":function($event){_vm.isNewPasswordVisible = !_vm.isNewPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VTextField,{staticClass:"mt-3",attrs:{"type":_vm.isCPasswordVisible ? 'text' : 'password',"append-icon":_vm.isCPasswordVisible ? _vm.icons.mdiEyeOffOutline:_vm.icons.mdiEyeOutline,"label":"Confirmar senha","outlined":"","dense":""},on:{"click:append":function($event){_vm.isCPasswordVisible = !_vm.isCPasswordVisible}},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})],1)],1)],1),_c(VCardText,[_c(VBtn,{staticClass:"me-3 mt-3",attrs:{"color":"primary"},on:{"click":_vm.updatePassword}},[_vm._v(" Save changes ")]),_c(VBtn,{staticClass:"mt-3",attrs:{"color":"secondary","outlined":""},on:{"click":_vm.cancelAction}},[_vm._v(" Cancel ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }