import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{class:{ 'dark-mode': _vm.isDark, 'light-mode': !_vm.isDark }},[_c(VCard,{staticClass:"ml-10 mr-10 px-5 py-5 d-flex justify-content-end"},[_c(VCol,{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('div',{staticClass:"container-profile"},[_c(VIcon,{staticClass:"me-6",on:{"click":function($event){return _vm.$router.push({ path: '/' })}}},[_vm._v(" "+_vm._s(_vm.icons.mdiHome)+" ")]),_c('app-bar-theme-switcher'),_c('app-bar-user-menu')],1)])],1)],1),(_vm.skeleton)?_c(VCol,{staticClass:"ml-10 mt-10",attrs:{"cols":"10"}},[_c(VSkeletonLoader,_vm._b({attrs:{"type":"article, actions"}},'v-skeleton-loader',_vm.attrs,false))],1):_c(VCard,{staticClass:"ml-10 mr-10 mt-10",attrs:{"id":"account-setting-card"}},[_c(VTabs,{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.icon},[_c(VIcon,{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('profile-infos',{attrs:{"account-data":_vm.accountSettingData}})],1),_c(VTabItem,[_c('profile-security',{attrs:{"account-data":_vm.accountSettingData}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }