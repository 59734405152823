<template>
  <div class="">
    <div
      class=""
      :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
    >
      <v-card class="ml-10 mr-10 px-5 py-5 d-flex justify-content-end">
        <v-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <div class="container-profile">
            <v-icon
              class="me-6"
              @click="$router.push({ path: '/' })"
            >
              {{ icons.mdiHome }}
            </v-icon>
            <app-bar-theme-switcher></app-bar-theme-switcher>
            <app-bar-user-menu></app-bar-user-menu>
          </div>
        </v-col>
      </v-card>
    </div>

    <v-col
      v-if="skeleton"
      class="ml-10 mt-10"
      cols="10"
    >
      <v-skeleton-loader
        v-bind="attrs"
        type="article, actions"
      ></v-skeleton-loader>
    </v-col>
    <v-card
      v-else
      id="account-setting-card"
      class="ml-10 mr-10 mt-10"
    >
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <profile-infos :account-data="accountSettingData" />
        </v-tab-item>
        <v-tab-item>
          <profile-security :account-data="accountSettingData" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiHome,
  mdiLockOpenOutline,
} from '@mdi/js'

import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import { getVuetify } from '@core/utils'
import ProfileInfos from './ProfileInfos.vue'
import ProfileSecurity from './ProfileSecurity.vue'

export default {
  components: {
    ProfileInfos,
    ProfileSecurity,
    AppBarUserMenu,
    AppBarThemeSwitcher,
  },
  data() {
    return {
      tab: '',
      tabs: [
        { title: 'Informações', icon: mdiAccountOutline },
        { title: 'Segurança', icon: mdiLockOpenOutline },

        // { title: 'Info', icon: mdiInformationOutline },
        // { title: 'Billing', icon: mdiBookmarkOutline },
        // { title: 'Notifications', icon: mdiBellOutline },
      ],
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 0,
      },
      icons: {
        mdiHome,
      },

      skeleton: true,

      accountSettingData: {},
    }
  },

  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },

  async created() {
    await this.getInfosProfile()
  },

  methods: {
    async getInfosProfile() {
      const userLogged = []
      await axiosIns.get('/api/v1/records/user/logged_in_user')
        .then(res => {
          userLogged.push(res.data.data)
        })

      await axiosIns.get(`/api/v1/records/user/edit/${userLogged[0].id}`)
        .then(res => {
          this.accountSettingData = res.data.data
        })
      this.skeleton = false
    },
  },
}
</script>
<style scoped>
.container-profile{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
