<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-title>
      Informações do perfil
    </v-card-title>
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6 container-image"
      >
        <v-progress-circular
          v-if="imageLoaded"
          indeterminate
          :size="25"
          color="gray"
        >
        </v-progress-circular>
        <v-img
          v-else
          :src="imageProfile"
        >
        </v-img>
      </v-avatar>

      <div>
        <v-file-input
          v-model="files"
          multiple
          dense
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          label="Escolher foto"
          :hidden="true"
        />
        <!-- <v-btn
          color="error"
          outlined
          class="mt-5"
        >
          Reset
        </v-btn> -->
        <p class="text-sm mt-5">
          Permite JPG, GIF ou PNG.
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountData.login"
              readonly
              label="Username"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountData.name"
              readonly
              label="Nome"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountData.cpf"
              readonly
              label="CPF"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountData.email"
              label="E-mail"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountData.cellphone"
              dense
              label="Celular"
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountData.company.fantasy_name"
              dense
              readonly
              outlined
              label="Loja"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              v-if="dialogUpload"
              color="primary"
              class="me-3 mt-4"
            >
              <v-progress-circular
                indeterminate
                :size="25"
                color="gray"
              >
              </v-progress-circular>
            </v-btn>
            <v-btn
              v-else
              color="primary"
              class="me-3 mt-4"
              @click="changeDataProfile"
            >
              Salvar mudanças
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="resetForm"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiCloudUploadOutline } from '@mdi/js'

export default {
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      imageLoaded: false,
      dialogUpload: false,
      icons: {
        mdiCloudUploadOutline,
      },
      status: ['Ativo', 'Inativo', 'Pendente', 'Fechado'],
      userInfos: {},
      files: [],
      imageProfile: '',
    }
  },

  async created() {
    await this.getInfosProfile()
  },

  methods: {
    async changeDataProfile() {
      this.dialogUpload = true
      if (this.files.length === 1) {
        const formData = new FormData()

        this.files.forEach(file => {
          formData.append('files[]', file)
        })

        await axiosIns.post(`/api/v1/records/user/profile_picture/${this.accountData.id}`, formData)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Tudo certo!',
              text: 'Foto do perfil atualizada!',
              timer: 3000,
            })
          })
          .catch(error => {
            this.$swal({
              icon: 'error',
              title: 'Erro ao atualizar a foto do perfil',
              showConfirmButton: false,
              text: error,
              timer: 3000,
            })
          })
      }

      const body = {
        name: this.accountData.name,
        cpf: this.accountData.cpf,
        email: this.accountData.email,
        cellphone: this.accountData.cellphone,
      }

      await axiosIns.put(`/api/v1/records/user/update/${this.accountData.id}`, body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso',
            showConfirmButton: false,
            text: 'Dados modificados!',
            timer: 3000,
          })
        })

      this.getInfosProfile()

      this.dialogUpload = false
      this.files = []
    },
    async getInfosProfile() {
      this.imageLoaded = true
      const userLogged = []
      await axiosIns.get('/api/v1/records/user/logged_in_user')
        .then(res => {
          userLogged.push(res.data.data)
        })

      await axiosIns.get(`/api/v1/records/user/edit/${userLogged[0].id}`)
        .then(res => {
          this.userInfos = res.data.data
        })

      await axiosIns.get(`/api/v1/records/user/display_photo/${this.accountData.id}`)
        .then(res => {
          this.imageProfile = res.data
          this.imageLoaded = false
        }).catch()
    },
  },
}
</script>
<style>
  .container-image{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50;
  }

  .button-changes{
    color: #000
  }
</style>
